<template>
  <v-parallax light src="@/assets/img/parallax-bg.jpg">
    <v-row class="justify-center align-center">
      <v-col class="col-12 col-md-6 col-xl-4 text-center">
        <v-avatar color="rgba(255,255,255,0.5)" width="150" height="150">
          <v-img :src="require('../assets/img/logo-alt.png')" contain height="100"></v-img>
        </v-avatar>
        <h1 class="font-weight-bold pt-3 mb-3">Welcome to Derby Emotional Wellness</h1>
        <p
          class="subheading font-weight-regular"
        >The only full service mental health clinic in Derby, KS. Psychotherapy and Medication Management available. Most insurance accepted.</p>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  //
};
</script>