import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Derby Emotional Wellness | Home'
    },
  },
  {
    path: '/about',
    name: 'About Us',
    meta: {
      title: 'Derby Emotional Wellness | About Us'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/forms',
    name: 'Patient Forms',
    meta: {
      title: 'Derby Emotional Wellness | Patient Forms'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    meta: {
      title: 'Derby Emotional Wellness | Contact'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/resources',
    name: 'Resources',
    meta: {
      title: 'Derby Emotional Wellness | Resources'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Resources.vue')
  },
  // catch all redirect
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router
