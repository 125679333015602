import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import "animate.css";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify/lib'

require('intersection-observer');

Vue.config.productionTip = false

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        gray: '#C0C0C0',
        white: '#FFFFFF',
        lightGray: '#b2b2b2'
      },
    },
  }
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('trim', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.trim()
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
