<template>
  <div class="home mb-12">
    <Welcome/>

    <v-row class="justify-center pb-6">
      <v-col class="col-10 col-xl-6 text-center">
        <h2 class="headline font-weight-bold my-3">What's next?</h2>
        <router-link
          v-for="route in routes" :key="route.name"
          :to="route.path"
          class="subheading mx-3"
        >
        {{ route.name | trim }}
        </router-link>
        <blockquote class="py-3">
          <p>We pride ourselves on providing an environment that helps in the healing process as we strive to support our patients in becoming happier and healthier human beings.</p>
        </blockquote>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue'

export default {
  name: 'home',
  components: {
    Welcome
  },
  data() {
    return {
      routes: this.$router.options.routes.slice(1,-1)
    };
  }
}
</script>

<style lang="scss">
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #003767;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
</style>
