<template>
  <v-app>

    <v-navigation-drawer
      v-model="sidebar"
      app
      disable-route-watcher
      disable-resize-watcher
      temporary
      width=270
    >
      <ul class="pa-6">
        <li class="text-left" v-for="route in routes" :key="route.name" >
          <router-link
            :to="route.path"
            @click.native="toggle"
          >
          {{ route.name | capitalize }}
          </router-link>
        </li>
      </ul>
    </v-navigation-drawer>

    <v-app-bar app
      src="@/assets/img/header-bg.jpg"
      clipped-left
    >
      <router-link to="/">
        <v-img :src="require('@/assets/img/logo-alt-sm.png')" max-width="60" max-height="60"></v-img>
      </router-link>
        <v-toolbar-title class="headline text-uppercase pl-2">
          <span class="white--text font-weight-black">Derby</span>
          <span class="white--text body-1 font-weight-thin">Emotional Wellness</span>
        </v-toolbar-title>
      <v-spacer></v-spacer>
      <span>
        <v-app-bar-nav-icon @click="sidebar = !sidebar" class="white--text"></v-app-bar-nav-icon>
      </span>
    </v-app-bar>
    <v-content>

        <v-container fluid class="pa-0">
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            mode="out-in"
          >
            <router-view />
          </transition>

          <v-bottom-navigation fixed>
            <v-row class="justify-center">
              <v-col class="col-12 text-center">
                &copy; {{ new Date().getFullYear() }} &ndash; <strong>Derby Emotional Wellness</strong>
              </v-col>
            </v-row>
          </v-bottom-navigation>

        </v-container>

    </v-content>


  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      sidebar: false,
      home: true,
      routes: this.$router.options.routes,
      appTitle: "Derby Emotional Wellness",
      pageTitle: "",
    };
  },
  methods: {
    isHome() {
      if (this.$router.currentRoute.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
    toggle() {
      this.sidebar = !this.sidebar
    }
  },
  beforeCreate() {
    if (this.$router.currentRoute.name === "Home") {
      //$('body').addClass('home');
    }
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Derby Emotional Wellness";
      if (this.$router.currentRoute.name === "Home") {
        //$('body').addClass('home');
      } else {
        return this.pageTitle = this.$router.currentRoute.name;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>